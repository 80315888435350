.createPost {
    position: relative;
    padding: 1rem;
}

.createPost .loadingOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
}

.postForm {
    display: flex;
    flex-direction: column;
}

.postForm .input {
    margin: 0.7rem 0;
}

.postForm .input input,
.postForm .input textarea {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border: 0;
    padding: 1rem;
    color: var(--text);
}

.postForm .input textarea:focus,
.postForm .input input:focus {
    outline: none;
    border: 1px solid var(--accent);
}

.postForm .input .errors {
    color: var(--danger);
    margin: 0;
}