:root {
    --background: #212121;
    --text-dim: rgba(256, 256, 256, .46);
    --text-normal: rgba(256, 256, 256, 1);
    --text-on-accent: rgba(0, 0, 0, 0.8);
    --accent: #76ff03;
    --accent-light: #b0ff57;

    --text-accent: var(--accent);

    --danger: #c30000;
}

html, body {
    background: var(--background);
    color: var(--text-normal);

    font-family: sans-serif;
}

button, input[type="submit"] {
    background: var(--accent);
    color: var(--text-on-accent);
    border-radius: 0;
    padding: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;

    border: 0;
    margin: 0 auto;
}