.embed {
    border-radius: 1rem 1rem;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.embedContainer, .embedContainer:visited {
    color: var(--text);
    text-decoration: none;
}

.embed .thumbnail {
    width: 100%;
}


.embed .details {
    padding: 1rem;
}

.embed .details h2 {
    color: var(--text-accent);
    margin-top: 0;
}

.embed .details span {
    color: var(--text-dim);
}