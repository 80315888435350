.postContainer {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
}

.postContainer .avatar {
    margin-right: 0.5rem;
    height: 5rem;
    width: auto;
}

.postContainer .avatar {
    height: 100%;
}

.postContainer .post {
    overflow: hidden;
    text-overflow: ellipsis;
}

.post .username {
    color: var(--text-dim);
}

.post .title {
    margin: 0.3rem 0;
    font-size: 1.3rem;
}

.post .content {
    margin: 0;
}

.postContainer .embed {
    margin-top: 1rem;
}