.postStream {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.postStream > * {
    margin-bottom: 2rem;
}

.postStream :last-child {
    margin-bottom: 0 !important;
}

.postStream .loading,
.postStream .error,
.postStream .end {
    color: var(--accent);
    text-align: center;
}
